import { Button, Col, Modal } from "react-bootstrap";
import { PrimaryPurpleButton } from "../../../components/Buttons/styles";

import styled from "styled-components";
export const ModalSuccessCreateCampaign = ({ show, handleCloseSuccessModal }) => {

    return (
        <ModalSuccessStyle show={show} onHide={handleCloseSuccessModal} centered>
            <Modal.Body>
                <img className="img-fluid mb-3" style={{ position: 'static' }} src={"/img/campaign/womam-finance.svg"} />
                <h2>Campanha criada!</h2>
                <p>A campanha foi criada com sucesso e ficará aplicada até o final do período determinado.</p>
                <Modal.Footer>
                    <PrimaryPurpleButton variant="primary" className="w-100" onClick={handleCloseSuccessModal}>
                        Voltar para campanha
                    </PrimaryPurpleButton>
                </Modal.Footer>
            </Modal.Body>
        </ModalSuccessStyle>
    );
}

const ModalSuccessStyle = styled(Modal)`
.modal-content{
    flex-direction: row;
}
.modal-body{
    width: 473px;
     height: 440px;
     text-align: center;
     padding: 30px 12px;
     h2{
        font-family: Lato;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: #218749
     }
     p{
        
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;

        color: #636060
     }
}
    .img-fluid {
        //width: 100%;
    }
    .modal-footer{
        padding: 30px 0px;
        border-top: 0px
    }
`;