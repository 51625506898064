import { Button, Col, Container, ListGroup, Modal, Row } from "react-bootstrap";
import styled from 'styled-components';

// #region Attend Course Page

export const PreviousButtonStyles = styled(Button)`
    border:none!important;
    box-shadow:none!important;
    border-top-left-radius:0px;
    border-bottom-left-radius:0px;
    border-top-right-radius:8px;
    border-bottom-right-radius:8px;
    position:fixed;
    top:50%;
    background: #514F4E!important;
    i{
        font-size:2.78vmin;
    }
    p{
        display:none;
        margin-bottom:0px;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 3.33vmin;

        margin-right:1.7vmin;
        margin-left:2.08vmin;

    }
    :hover{
        p{
            display:inline-block!important;
        }
    }

    @media screen and (max-width: 990px){
        display: none;
    }
`;

export const NextButtonStyles = styled(Button)`
    z-index:100;
    border:none!important;
    box-shadow:none!important;
    border-top-left-radius:8px;
    border-bottom-left-radius:8px;
    border-top-right-radius:0px;
    border-bottom-right-radius:0px;
    background: #514F4E!important;

    i{
        font-size:2.78vmin;
    }
    p{
        display:none;
        margin-bottom:0px;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.22vmin;
        line-height: 3.33vmin;

        margin-left:2.08vmin;
        margin-right:2.08vmin;
    }
    &:hover{
        p{
            display:inline-block!important;
        }
    }

    position:fixed;
    top:50%;
    right:0px;
    ${({ sideMenuIsOpen }) => sideMenuIsOpen && `
        margin-right:33.34vw;
        right:0%;
    `}
    @media screen and (max-width: 990px){
        display: none;
    }
`;


export const AttendCourseContainer = styled(Container)`
    .page-row{
        min-height: 90vh;
    }
    .row{
        margin:0px;
    }    
`;

// #endregion Attend Course Page


export const LessonYoutubePreview = styled.iframe`
    .text{
        background-color:#000;
        z-index:100;
        height: 5vmin;
        width: 100vmin;
    }
    background-color:#000;
    height: 58vmin;
    width: 100%;
    overflow: auto;
    ${({ sideMenuIsOpen }) => sideMenuIsOpen && `
        height:73vmin;
    `}
`;
export const LessonVideoPreview = styled.video`
    .text{
        background-color:#000;
        z-index:100;
        height: 5vmin;
        width: 100vmin;
    }
    background-color:#000;
    height: 58vmin;
    width: 100%;
    overflow: auto;
    ${({ sideMenuIsOpen }) => sideMenuIsOpen && `
        height:73vmin;
    `}
`;
export const LessonRichTextPreviewContainer = styled.div`
    background-color:#fff;    
    width: 100%;
    padding-bottom:5vmin;
    margin-top:4vmin;
    padding-left:4vmin;
    padding-right:4vmin;

`;
export const LessonEmbedPreview = styled.embed`
    .text{
        background-color:#000;
        z-index:100;
        height: 5vmin;
        width: 100vmin;
    }
    background-color:#cecece;
    height: 75vmin;
    width: 100%;
`;
export const AttendCourseLessonContentStyles = styled(Col)`
    width:100%;
    min-height:91vh;
    max-height:91vh;
    overflow-y:scroll;
    ::-webkit-scrollbar{
        position:absolute;
        width: 0.8vmin ;
        background: #E8E8E8;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0.4vmin ;
        background: #B9B8B8;;
        width: 0.4vmin ;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background:#B9B8B8;; 
        width: 1.12vmin ;

    }


`;
export const LessonContentDescription = styled(Col)`
    max-width:77vw;
    h2 {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.7vmin;
        line-height: 3.3vmin;
        color: #636060;
        margin-bottom:0px;
    }
    h3 {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 2.2vmin;
        line-height: 3.3vmin;
        color: #636060;
        margin-bottom:0px
    }
    h4{
            /* Texto de Apoio */
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 1.5rem;
            color: #636060;
        
    }
    i {
        font-size: 3.3vmin;
        color: #ffffff;
    }
    p {
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.23vmin;
        line-height: 3.34vmin;
        color: #777575;
    }    
    .card {
        border: 1px solid #218749;
        box-sizing: border-box;
        border-radius: 8px;
    }
    Button{
        /* Brand/Primary/Medium */
        background: #6468C8;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        /* Auto Layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;

        position: absolute;
        width: 30vmin;
        height: 6vmin;
        /* 
        left: calc(50% - 155px/2 - 522.5px);
        top: calc(50% - 48px/2 + 355px); */
        position: static;

        /* Texto */
        
        left: 52px;
        top: 14.5px;

        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2vmin;
        line-height: 19px;

        /* Neutral/1 */
        color: #FFFFFF;

        /* Inside Auto Layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 16px;
        margin-bottom: 2rem;
        outline: none;
        &:focus{
        box-shadow:none!important;
    }
    &:active{
        box-shadow:none!important;
    }

        i {
            font-size: 2.8vmin;
            margin-right: 10px;
        }
    }
    .status-awaiting-correction-task{
        color: #6468C8;
    }
    .correction-task {
        border: 1px solid #218749;
        box-sizing: border-box;
        border-radius: 8px;
    }
`;
export const RichText = styled.div`
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
    }
    blockquote{
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: 400;
            font-size: 1.95vmin;
            line-height: 2.78vmin;
            padding: 0 0.7vmin 0 2.8vmin;
            color:#999;
        }

        border: solid #b1b1b1;
        border-width: 0 0 0 0.7vmin;
    }
    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 3.88vmin;
        line-height: 4.44vmin;
    }
    h2{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 3.33vmin;
        line-height: 3.88vmin;
    }
    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.78vmin;
        line-height: 3.33vmin;
    }
    h4{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
    }
    h5{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.22vmin;
        line-height: 2.5vmin;
    }
    h6{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 1.95vmin;
        line-height: 2.5vmin;
    }
`;

// #region ModalInfo

export const ModalCourseInfoStyles = styled(Modal)`
    button.close span {        
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 4.5vmin;        
        color: #0C8292;
    }

    h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.7vmin;
        line-height: 3.3vmin;
    }
    .course-title {
        color: #6468C8;
    }
    iframe{
        width:90%;
        height:40vh;
    }

    @media screen and (max-width: 990px){    
        max-height: 500px;
        position: fixed;
        top: 35px;

        .modal-body {
            position: relative;
        }

        .modal-header {
            background:#FFF;
            border: 0;
            position: fixed;
            z-index: 9;
            width: 94.4%;  
            padding: auto !important;   
            box-shadow: 0vmin 0.14vmin 0.84vmin rgb(81 79 78 / 20%);
            margin-left: -15px;
            margin-top: -10px;
        }

        span {
            font-size: 5vmin;
        }

        .modal-title {
            font-size: 6vmin;
            font-weight: 600;
            position: relative;
            top: 5px;
        }
        
        h1 {
            font-size: 5vmin;
            padding: 0 !important;
        }

        span {
            padding: 0 !important; 
        }
        iframe{
            width:100%;
        }

        .mt-4 {
            margin-top: 20px !important;
        }

        .course-title {
            font-size: 5.3vmin;            
            line-height: 8vmin;
            margin-top: 40px;
        }

        button.close span {  
            font-size: 10.5vmin;
        }
    }
`;
export const ModalCourseInfoHeader = styled(Container)`    
    width: inherit;    
    background: #F9F9F9;
    box-shadow: 0px 1px 6px rgba(81, 79, 78, 0.2);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #636060;
`;

// #endregion ModalInfo

// #region SideMenu

export const CollapseMenuButton = styled(Button)`   
position: absolute;
right:0px;
z-index:2;
display:grid;
border:none!important;
box-shadow:none!important;
background: #E8E8E8!important;
border-radius: 0px 0px 0px 1.12vmin;
i{
    color:#0C8292;
    font-size:3.33vmin;
}

`
export const HoldNextPrev = styled(Row)`  
    position: fixed;
    bottom: 0px;
    padding: 10px;
    width: 100%;
    background: #F9F9F9;
    box-shadow: 0px -1px 6px rgba(81, 79, 78, 0.2);

    ul {
        list-style-type: none;
        display: flex;
        justify-content: space-between;    
        padding: 0;         
    }

    button {
        background: transparent;
        border: 0;
        color: #6468C8;
        font-weight: 600;
    }

    svg {
        margin: 0px 7px;
    }
`

export const AttendCourseSideMenuItemStyle = styled(ListGroup)`
    .list-group-item{
        border-bottom:none!important;
        padding:0px;
    }
    .bi-file-earmark-check{
        color:#218749!important;
    }
    i.bi-check2-circle{
        color:#218749!important;
    }
    i.bi-lock{
        color: #514F4E!important;
    }
    i.bi-dash-circle{
        color: #995C00!important ;
    }
    i.bi-clock{
        color: #6468C8!important ;
    }

    h3{
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.78vmin;
        color: #777575;
    }
    p{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 1.95vmin;
        line-height: 2.78vmin;
        color: #777575;
        margin-bottom:10px;
    }

    
    i.bi-file-earmark-richtext, i.bi-puzzle, i.bi-question-circle, i.bi-award{
        color: #777575!important;
    }
`
export const AttendCourseSideMenuStyle = styled(ListGroup)`
    overflow-y:scroll;
    min-height:91vh;
    max-height:91vh;
    height:100%;
    border:none!important;
    border-left: 0.14vmin solid #E8E8E8!important;
    background: #F9F9F9;
    
    ::-webkit-scrollbar{
        position:absolute;
        width: 0.8vmin ;
        background: #E8E8E8;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0.4vmin ;
        background: #B9B8B8;
        width: 0.4vmin ;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background:#B9B8B8;; 
        width: 1.12vmin ;

    }

    .list-group-item{
        background-color:#F9F9F9;
        padding:0px;
        border:none;
        border-bottom: 0.14vmin solid #E8E8E8;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
        button{
            .col{
                text-align:left;
            }
            background-color:#F9F9F9;
            border:none!important;
            box-shadow:none!important;
            :hover:enabled{
                background:#E8E8E8;
            }
            :active:enabled{
                background-color:#ffd4c8;

            }
            i{
                font-size: 3.33vmin;
                color: #EA694C;
            }

            width:100%;
            background-color:#F9F9F9;
            border:none!important;
            box-shadow:none!important;

        }
        button.selectedModule{
            border-left: 5px solid #EA694C!important;
        }
        button.collapsedSelection{
            background: #FCE9E4;
            &:hover{
                background:#E8E8E8;
                
            }
        }
    }
    .list-group-item.lesson{
        background-color:#FFFFFF!important;
        button{
            background-color:#FFFFFF;

            &:active:enabled{
                background-color: #ffd4c8;
            }
            &:hover:enabled{
                background-color:#E8E8E8!important;
            }

            i{
                font-size: 3.33vmin;
                color: #EA694C;
            }
        }
    }
    .list-group-item:first-child{
        background-color:#F9F9F9;
        button{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.22vmin;
            line-height: 2.64vmin;

            /* Brand/Tertiary/Medium */

            p.hide{
                margin-bottom:0px;
                color: #0C8292!important;
            }
            i{
                font-size: 3.33vmin;
                color: #0C8292;
            }
        }

    }

    .list-group-item:last-child{
        border-bottom:none!important;
    }

    h1{
        margin-bottom:0px;
        
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.33vmin;
        color: #514F4E;
    }

    h2{
        margin-bottom:0px;
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.77vmin;
        color: #636060;
        text-align: left;
    }

    .btn.active{
        background-color:#FCE9E4!important;
    }
    i.bi-lock{
        color: #514F4E
    }
    i.bi-check2-circle{
    color:#218749!important;
}
@media screen and (max-width: 990px) { 

   h1 {
    font-size: 4.6vmin;
   }

   h2 {
    font-size: 4.7vmin;
    line-height: 6vmin;
   }

   h3 {
    font-size: 4.7vmin;
    line-height: 6vmin;
   }

   p {
    font-size: 4.4vmin;
   }

   i {
    font-size: 8vmin !important;
    position: relative;
    right: 8px;
   }
}
`

// #endregion SideMenu

// #region AttendLessonContent

 export const AttendMediaContentComponentStyles = styled(Row)`
    .list-group-horizontal{
        border-radius:1vmin!important;
        background-color:#E8E8E8;
        padding:none;
    }
    .list-group-item{
        padding:0px!important;
        border-radius:3vmin!important;
        border:none;
        background-color:#E8E8E8;
        i{
            font-size:3.5vmin;
        }

    }
    .list-group-item:first-of-type{
        border-right:0.11vmin solid #B9B8B8;
    }
    .list-group-item:last-of-type{
        border-left:0.11vmin solid #B9B8B8;
    }
    .open-new-tab-button{
        text-align:left;
        width:auto;
        height:14.44vmin; 
        box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2);
        border: 0.14vmin solid #F9F9F9;
        border-radius: 1.11vmin;
        background-color: #FFF;
        i{
            font-size:3.33vmin;
            color:#0C8292;
        }
        h3{
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 2.83vmin;
            line-height: 3.33vmin;
            color: #0C8292;
            margin-bottom:0px;
        }
        p{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            color: #636060;
            margin-bottom:0px;

        }
        &:hover{
            background-color: #E1F4F7;
            border: 0.14vmin solid #F9F9F9;
        }
        &:focus, &:active{
            background-color: #d1f3f8!important;
            box-shadow: 0px  0.14vmin  0.56vmin rgba(75, 75, 75, 0.2)!important;
            border: none!important;
        }
        &:focus:after, &:active:after{
            content: '';
            position: absolute;
            top: -0.5vmin;
            left: 1.2vmin;
            bottom: -0.5vmin;
            right: 1.2vmin;
            border: 0.20vmin solid #0B717E!important;
            border-radius: 1.11vmin;
        }
    }
    .bi.bi-trash{
        font-size:4vmin;
    }
    video{
        width:100%;
        border:none;
    }
    iframe{
        width:100%;
        min-height:${props => props.fullSize? "70vh":"40vh"};
        border:none;
    }

    img{
        width:100%;
    }

    object, iframe {
        width:100%;
        height:70vh;
        border:none;
    }

    @media screen and (max-width: 990px){
        .py-3 {
            .row {
                margin: 0;
                padding: 0;
            }

            .col-md-6 {
                padding:0;
            }
        }
    }
`;

// #endregion AttendLessonContent

// #region Task

export const AttendTaskComponentStyles = styled(Col)`
    padding:7vmin 4vmin 5vmin;
    max-height:91vh;
    overflow-y:scroll;

    ::-webkit-scrollbar{
        position:absolute;
        width: 0.8vmin ;
        background: #E8E8E8;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0.4vmin ;
        background: #B9B8B8;;
        width: 0.4vmin ;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background:#B9B8B8;; 
        width: 1.12vmin ;

    }

    .delivery-task-button{
        margin-top:2vmin;
        padding:1.5vmin 0;
        i{
            font-size:3vmin;
        }
        .col-md-auto{
            margin: auto 0;
        }
    }
    h1{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 2.77vmin;
        line-height: 3.33vmin;
        color: #514F4E;
    }
    h2{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.77vmin;
        color: #636060;
    }
    h3{
        margin-bottom:3.5vmin;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 2.22vmin;
        line-height: 2.77vmin;
        color: #6468C8;
    }
    p, pre{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 2.22vmin;
        line-height: 3.33vmin;
        color: #636060;
    }

    pre {
        white-space:pre-wrap;
    }

    .task-not-sent{
        margin-top:2vmin;
        margin-bottom:4vmin;

        .col-md-auto{
            margin-bottom:auto;
            margin-top:auto;
        }
        p{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            color: #995C00;
            margin-bottom:0;


        }
        p.task-required{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 1.944vmin;
            line-height: 3.33vmin;
            color: #636060;
        }
        .bi-dash-circle{

            font-size: 3.5vmin;
            color: #995C00;
        }
    }
    .task-sent{
        margin-top:2vmin;
        margin-bottom:4vmin;

        .col-md-auto{
            margin-bottom:auto;
            margin-top:auto;
        }
        p{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 2.22vmin;
            line-height: 3.33vmin;
            color: #218749;
            margin-top:0.5vmin;
            margin-bottom:0;
        }
        .bi-check-circle{

            font-size: 3.5vmin;
            color: #218749;
        }
    }
    .task-feedback{
        .col{
            margin:2vmin 2vmin 4vmin;
            background: #F1F5F3;
            border: 0.14vmin solid #218749;
            border-radius: 1.11vmin;
            .col-md-auto{
                margin: auto 0;
                i{
                    font-size:3.33vmin;
                    color: #218749;
                }
                h1{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 2.22vmin;
                    line-height: 3.33vmin;
                    color: #218749;
                    margin-bottom:0;
                }
                h2{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 2.22vmin;
                    line-height: 2.77vmin;
                    color: #636060;
                }
                p{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 2.22vmin;
                    line-height: 3.33vmin;

                    color: #636060;
                }
            }
        }
    }

    @media screen and (max-width: 990px){
        
        h1 {
            font-size: 5vmin;
            line-height: 8vmin;
        }

        h2, h3 {
            font-size: 5vmin;
            margin-top: 15px;
            line-height: 8vmin;
        }

        p {
            font-size: 4vmin;
            line-height: 8vmin;
        }

        .task-required {
            font-size: 5vmin !important;
            margin-top: 20px;
            line-height: 6vmin !important; 
        }

        .task-not-sent {

            i{
                font-size: 6vmin !important;
            }

            p {
                font-size: 5vmin;
                line-height: 8vmin;
            }            
        }

        .task-feedback {  
            
            .col {
                padding-bottom: 25px !important;
            }

            h1, h2 {
                font-size: 5vmin !important;
                margin: 0 !important;
            }          

            p {
                margin: 0 !important;
            }
        }

        .task-sent {
            p {
                font-size: 5vmin !important;
            }
        }

        i {
            font-size: 5vmin !important;
        }        

        .btn-task-mobile {
            padding: 6px 40px 6px 40px !important;
            
            width: 100%;

            i {
                margin-right: 10px;
            }
        }
    }
`

export const SendTaskModal = styled(Modal)`    

    .modal-content{
        border-radius:1.11vmin;
        border-radius: 12px !important;        
    }
    .modal-header{
        border:none;
        h1{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 3.33vmin;
            line-height: 28px;
            color: #514F4E;
        }
        button{
            padding:1vmin 2vmin 2vmin;
        }
        span{
            font-size:5vmin;
        }
    }
    .modal-body{
        padding-top:0;
        .choose-task-msg{
            margin-top:5vmin;
            margin-bottom:8vmin;
            i{
                font-size:3vmin;
                color:#C57700;
            }
            .col-md-auto{
                margin:auto 0;
            }
        }
        h1{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 2.5vmin;
            line-height: 24px;
            color: #514F4E;
            margin-top:0.5vmin;

            margin-bottom:0;  
        }
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 2.22vmin;
            line-height: 24px;
            color: #636060;
            margin-top:0.5vmin;
            margin-bottom:0;
        }
        h3{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 1.97vmin;
            line-height: 2.77vmin;
            color: #636060;
            margin:0;
        }
    }

    .confirm-task-file{
        .bi-card-checklist{
            color:#6468C8;
            font-size:3vmin;
        }
    }
    .confirm-exit-modal{
        h1{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 2.77vmin;
            line-height: 8vmin;
            /* line-height: 33.33vmin; */
            color: #514F4E;   
        }
    }
    .successfull-sent{
        img{
            max-height:30vmin;
        }
        h2{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 2.934vmin;
            line-height: 3.33vmin;
            /* or 120% */
            text-align: center;
            color: #218749; 
        }
        p{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 2.22vmin;
            line-height: 24px;
            color: #636060;
        }
        button{
            width: 100%;
            padding: 1vmin!important;
            p{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 2.22vmin;
                line-height: 2.5vmin;
                padding:0;
            }
            i{
                font-size:4vmin;
            }
        }
    }
    .modal-footer{
        border:none;
        button{
            width: 100%;
            padding: 1.5vmin;
            p{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 2.22vmin;
                line-height: 2.5vmin;
                padding:0;
            }
        }
        .bi-upload{
            font-size:2.5vmin;
        }
        .bi-arrow-repeat{
            font-size:2.5vmin;
        }
    }
    
    .confirm-task-msg {
        h4 {
            font-weight: normal;
            font-size: 2.3vmin;
            margin-top: 7px;
        }
    }    

    @media screen and (max-width: 990px){

        .confirm-task-file{
           h1 {
            font-size: 5vmin;
            line-height: 7vmin;
           }

           h2 {
            font-size: 6vmin;
            line-height: 7vmin;
            margin-bottom: 18px;
           }

           h3 {
            font-size: 5vmin;
            line-height: 7vmin;
           }   
           
           h4 {
            font-size: 5vmin;
            line-height: 6vmin !important;
            font-weight: normal !important;
           }
        } 

        .confirm-task-msg {

            h2 {
                line-height: 5.2vmin;
            }

            i {
                position: relative;
                top: -10px;
                font-size: 8.4vmin !important;
            }
        }

        .confirm-exit-modal{
            h1{
                font-size: 6vmin !important; 
            }
        }
    }

`;

// #endregion Task

// #region Certificate

export const CertificateContainer = styled(Col)`    
    width:100%;  
    .row.download{
        position: relative;
        z-index:99;
        margin-top:-6.66vmin;
        height:6.66vmin;
        background-color:#313130a6;
        button{
            background-color:#313130a6!important;
            border:none!important;
            box-shadow:none!important;
            i{
                font-size: 3vmin;
            }
        }
    }  
    
    @media screen and (max-width: 990px){

        .row.download{
            background: transparent; 

            button {
                display: none;
            }
        }

        .hold-download-mobile {
            text-align: center;
            margin-top: 20px;

           img {
            max-width: 90% !important;
           }
        }
    }
`

export const CertificateArea = styled(Row)`
    background-color: #514F4E;
    overflow-y:hidden ; 
`

export const CertMainImage = styled.div`    
    object {
        width: 30vmin;
    }
`

export const CertContent = styled(Col)`
    /* background-color: #fff;*/
    margin-top: 2.8vmin;
    margin-left: 15vmin;   

    max-height:70.63vmin!important;
    max-width: 116.94vmin!important;
    z-index:0;

    @media print {
        margin-top: 2.8vmin;
        margin-left: 15vmin; 
        position:absolute;
        left:2.7cm;
        @page { 
            size: landscape; 
            margin:0cm!important;
        }
        .left-page-marker{
            position:absolute;
            left:-2.5cm;
            height:21cm;
            width:9cm;
        }
        .certificamos-que{
            display:flex;
            position:absolute;
            top:1cm;
            left:0.8cm;
            margin-block-start: 0cm;
            p{
                font-size:14pt!important;
            }
        }
        .student-name{
            position:absolute !important;
            top:3cm !important;
            left:0.8cm !important;
            font-size:30pt!important !important;
            color: #EA694C;
        }
        .course-text-info{
            position:absolute;
            top:9cm;
            left:1.3cm;
            width:23cm;
            p{
                font-size:14pt!important;
            }
        }
        .institution-name{
            position:absolute;
            top:14cm;
            left:1.3cm;
            p{
                font-size:14pt!important;
            }
        }
        .reedu{
            position:absolute;
            top:16cm;
            width:auto;
            .signature{
                position:absolute;
                top:1cm;
                margin-left:0.7cm!important;
                width:4cm;
            }
            .logo{
                position:absolute;
                left:5cm;
                margin-left:1cm!important;
                width:5.5cm;
            }
            .partner{
                left:12cm;
                position:absolute;
                width:5cm;
                top:-0.4cm;
            }
        }
        .bigbrain{
            position:absolute;
            top:16cm;
            width:auto;
            .signature{
                position:absolute;
                top:1cm;
                margin-left:0.7cm!important;
                width:4cm;
            }
            .logo{
                position:absolute;
                top:-0.5cm;
                left:7.1cm;
                margin-left:1cm!important;
                width:3.5cm;
            }
            .partner{
                left:13.5cm;
                position:absolute;
                top:-0.7cm;
                width:6cm;
            }
        }
    }
    h3 {
        font-family: Lato !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 3.5vmin !important;
        line-height: 3.3vmin !important;
        color: #EA694C !important;
        margin-bottom: 6vmin !important;
    }    
                
    p {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.2vmin;
        line-height: 2.7vmin;
        color: #514F4E;
    }
    img.signature{
        max-width:18vmin;
    }
    img.reedu{
        max-width:18vmin;
    }
`

export const BottomText = styled(Row)`
    i {        
        margin-right: 1vmin;
    }

    h3 {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5vmin;
        line-height: 3.3vmin;
        color: #218749;
        margin-bottom: 3vmin;
    }    
                
    p {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 2.2vmin;
        line-height: 2.7vmin;
        color: #514F4E;        
    } 

    @media screen and (max-width: 990px){
        padding-top: 30px;

        &.modal-bottom-txt {
            padding-top: 50px;
        }

        i {
            font-size: 7vmin;
        }

        h3 {
            font-size: 5vmin;
        }

        p {
            font-size: 5vmin;
            line-height: 7vmin;
            font-weight: normal;
        }
    }
`

// #endregion Certificate
