import pt from "date-fns/locale/pt";
import { Button, Col, Container, Form, Nav, Row } from "react-bootstrap"
import CreateCampaignHeader from "./CreateCampaignHeader"
import { useEffect, useState } from "react"
import styled from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";
import { PrimaryGreenButton, PrimaryPurpleButton, TertiaryGreenButton } from "../../../components/Buttons/styles";
import Search from "../../../components/Search/Search";
import { ModalSuccessCreateCampaign } from "./ModalSuccessCreateCampaign";
import { ModalErrorCreateCampaign } from "./ModalErrorCreateCampaign";
import { feedBack, isInvalidFeedBack, useValidation } from "../../../util/Validation";
import { requiredFieldMsg } from "../../../util/messagesValidation";
import CampaignServices, { ICampaignPayload } from "../../../services/CampaignServices";
import {  formatDateToSend } from "../../../util/Formatter";

const campaignServices = new CampaignServices();

export const CreateCampaignPage = () => {
    const [activeTab, setActiveTab] = useState<boolean>(true);
    const [isLoading, setLoading] = useState(false);
    const [nameCampaign, setNameCampaign] = useState<string>('');
    const [discount, setDiscount] = useState<number>(0);
    const [numberAccesses, setNumberAccesses] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [listCourses, setListCourses] = useState([{
        canCreateCampaign: false,
        id: '',
        selected: false,
        title: ''
    }]);
    const [
        validationForm,
        setValidationFormField,
        validationErrors,
        setNewValidationErrors,
    ] = useValidation();
    registerLocale("pt", pt);



    useEffect(() => {
        updateValidation();
        function updateValidation() {
            validationForm.current = {
                nameCampaign,
                discount,
                numberAccesses,
            };
        }
    }, [nameCampaign, discount, numberAccesses]);
    useEffect(() => {
        requestCourses()
    }, [searchFilter]);


    const handleShowSuccessModal = () => setShowSuccessModal(true);
    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.href = "/controlpanel/campaigns";
    }
    const handleShowErrorModal = () => setShowErrorModal(true);
    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
        window.location.href = "/controlpanel/campaigns";
    }


    function findErrors() {
        const errors: any = {};
        if (validationForm.current) {
            if (
                !validationForm.current.nameCampaign ||
                validationForm.current.nameCampaign === ""
            )
                errors.nameCampaign = requiredFieldMsg;
            else errors.nameCampaign = undefined;

            if (
                !validationForm.current.discount ||
                validationForm.current.discount === "" ||
                validationForm.current.discount === "0"
            )
                errors.discount = requiredFieldMsg;
            else errors.discount = undefined;
            if (
                !validationForm.current.numberAccesses ||
                validationForm.current.numberAccesses === "" ||
                validationForm.current.numberAccesses === "0"
            )
                errors.numberAccesses = requiredFieldMsg;
            else errors.numberAccesses = undefined;
            if (
                !validationForm.current.discount ||
                validationForm.current.discount === "" ||
                validationForm.current.discount === "0"
            )
                errors.discount = requiredFieldMsg;
            else errors.discount = undefined;
        }
        const result = setNewValidationErrors(errors);
        return result;
    }



    function handleNextStep() {
        setLoading(true)
        const hasErros = findErrors()
        if (!hasErros) {
            setActiveTab(false)
        }
        requestCourses()

    }

    function requestCourses() {
        campaignServices.getCampaignCoursesLinked(startDate, endDate, searchFilter)
            .then(response => {
                setListCourses(response.result.items.map(item => {
                    return {
                        ...item,
                        selected: false // Define a propriedade 'selected' como false inicialmente
                    };
                }))
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })

    }
    function saveCampaign() {
        //validar erros se existe no mínimo 1 curso vinculado
        const payload: ICampaignPayload = {
            name: nameCampaign,
            discountPercentage: discount,
            maxSoldQnt: numberAccesses,
            campaignStartDate: formatDateToSend(startDate),
            campaignEndDate: formatDateToSend(endDate),
            coursesId: listCourses.filter(item => item.selected === true && item.canCreateCampaign === true).map(item => item.id)
        }
        campaignServices.CreateCampaign(payload)
            .then(response => {
                handleShowSuccessModal()
                setLoading(false)
            }).catch(err => {
                console.log(err)
                handleShowErrorModal()
                setLoading(false)
            })
    }

    function ClearFilters() {
        setSearchFilter("");
    }

    function handleClick(index) {
        const list = [...listCourses];
        const item = list[index];

        item.selected = !item.selected;
        list[index] = item;
        setListCourses(list);
    }


    function handleSelecteAll() {
        const areAllCourses = listCourses.filter((item: any) => item.canCreateCampaign === true)
        const areAllSelected = areAllCourses.every(item => item.selected);
        const updatedItems = listCourses.map(item => ({
            ...item,
            selected: !areAllSelected
        }));
        setListCourses(updatedItems);
    };


    const renderActiveTab = (activeTab: boolean) => {
        switch (activeTab) {
            case true:
                return (
                    <>
                        <Form className="container mt-5">
                            <Form.Group controlId="nameCampaign">
                                <Form.Label><h2>Nome da Campanha:</h2></Form.Label>

                                <Form.Control
                                    placeholder="Escreva um nome"
                                    type="text"
                                    value={nameCampaign}
                                    isInvalid={isInvalidFeedBack(
                                        "nameCampaign",
                                        validationErrors
                                    )}
                                    onChange={(e) => [setNameCampaign(e.target.value), setValidationFormField("nameCampaign", e.target.value)]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {feedBack("nameCampaign", validationErrors)}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="discount">
                                <Form.Label><h2>Porcentagem de Desconto:</h2></Form.Label>
                                <div className="sub-title">Desconto que será ofertado na campanha</div>
                                <Form.Control
                                    minLength={0}
                                    maxLength={100}
                                    placeholder="0%"
                                    type="number"
                                    value={discount}
                                    isInvalid={isInvalidFeedBack(
                                        "discount",
                                        validationErrors
                                    )}
                                    onChange={(e) => [setDiscount(parseFloat(e.target.value)), setValidationFormField("discount", e.target.value)]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {feedBack("discount", validationErrors)}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="numberAccesses">
                                <Form.Label><h2>Nº de Acessos por Curso:</h2></Form.Label>
                                <div className="sub-title">Número de pessoas com acesso ao desconto em cada curso adicionado na campanha</div>
                                <Form.Control
                                    placeholder="Digite um número"
                                    type="number"
                                    value={numberAccesses}
                                    isInvalid={isInvalidFeedBack(
                                        "numberAccesses",
                                        validationErrors
                                    )}
                                    onChange={(e) => [setNumberAccesses(parseFloat(e.target.value)), setValidationFormField("numberAccesses", e.target.value)]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {feedBack("numberAccesses", validationErrors)}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="periodo">
                                <Form.Label><h2>Período da Promoção:</h2></Form.Label>
                                <div className="sub-title">Defina uma data de início e uma data final para a promoção</div>
                                <Row>
                                    <Col sm={6} className="px-0">
                                        <Form.Label column sm={12}>
                                            <div className="sub-title">Data início</div>
                                        </Form.Label>
                                        <Col sm={12}>
                                            <DatePicker
                                                className="input-date w-100"
                                                placeholderText={
                                                    "dd/mm/aaaa"
                                                }
                                                dateFormat="dd/MM/yyyy"
                                                selectsRange={false}
                                                minDate={new Date()}
                                                locale="pt"
                                                selected={startDate}
                                                onChange={(date) => date && setStartDate(date as Date)}
                                                todayButton="Hoje"
                                            />
                                            <i className="bi bi-calendar3 icon-calendar"></i>
                                        </Col>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Label column sm={12}>
                                            <div className="sub-title">Data fim</div>
                                        </Form.Label>
                                        <Col sm={12}>
                                            <DatePicker
                                                className="input-date w-100"
                                                placeholderText={
                                                    "dd/mm/aaaa"
                                                }
                                                dateFormat="dd/MM/yyyy"
                                                selectsRange={false}
                                                minDate={startDate}
                                                locale="pt"
                                                selected={endDate}
                                                onChange={(date) => date && setEndDate(date as Date)}
                                                todayButton="Hoje"
                                            />
                                            <i className="bi bi-calendar3 icon-calendar"></i>
                                        </Col>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>

                    </>
                )
            case false:
                return (<>
                    <Row className="">
                        <Col>
                            <Row className="row pl-3 ml-0">
                                <Col className="col-md-12 pl-0 ml-0">
                                    <h3>Vincular Curso(s)</h3>
                                </Col>
                            </Row>
                            <Row className="row pl-3 pt-2">
                                <Col className="col-md-12 ">
                                    <h4>Para qual curso será essa campanha? Os cursos já vinculados em campanhas ativas não
                                        podem ser selecionados</h4>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 p-0">
                        <Col md={searchFilter !== "" ? 10 : 12}>
                            <Search
                                setSearchFilter={setSearchFilter}
                                searchFilter={searchFilter}
                                placeholder="Nome da campanha"
                            />
                            <i className="bi bi-search"></i>
                        </Col>
                        <Col md={2}>
                            {searchFilter !== "" && (
                                <Button
                                    className="clear-filter mx-1"
                                    onClick={ClearFilters}
                                    title="Limpar filtro"
                                >
                                    <i className="bi bi-x"></i>
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Col md={12} className="mt-2">
                        <Form.Check
                            className="button-radio button-radio-spaces"
                            //checked={entity.active}
                            // disabled={entity.disabled}
                            label='Selecionar todos os cursos'
                            onChange={() => handleSelecteAll()}
                        />
                    </Col>
                    <Col><div className="line my-3 w-100"></div></Col>
                    <Row className="ml-1">
                        {listCourses && listCourses.map((item: any, index: any) => {
                            return (

                                <Col md={6} className="my-2" key={item.index}>
                                    <Form.Check
                                        className="button-radio button-radio-spaces"
                                        checked={item.selected}
                                        disabled={!item.canCreateCampaign}
                                        label={item.title}
                                        onChange={() => handleClick(index)}
                                    />
                                </Col>
                            )
                        })}

                    </Row>



                </>)
            default:
                return (
                    <></>
                );
        }
    };


    return (
        <>
            <CreateCampaignHeader titleHeader="Criar Campanha"/>
            <CreateCampaignStepBarStyles fluid={true} className="">
                <Row>
                    <StepCreateCampaignTabStyles className="pl-0 ml-0 mx-auto w-100">
                        <Nav variant="pills" defaultActiveKey="/home" className="px-3 mt-2 mb-4 pl-0 ml-0 w-100 mx-auto">
                            <Nav.Item className="mx-1">
                                <Nav.Link
                                    active={activeTab === true}
                                    //onClick={() => setActiveTab(true)}
                                    className="pt-3"
                                >
                                    {activeTab === true ? <>
                                        <i className="bi bi-clock mr-2" /> Dados da Campanha
                                    </> : <>
                                        <i className="bi bi-check2-circle mr-2" /> Dados da Campanha
                                    </>}


                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mx-1">
                                <Nav.Link
                                    active={activeTab === false}
                                    //onClick={() => setActiveTab(false)}
                                    className="pt-3"
                                //disabled={activeTab === true}
                                >{activeTab === false ? <>
                                    <i className="bi bi-clock mr-2"></i>Vincular Cursos
                                </> : <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-2-circle mr-2" viewBox="0 0 16 16">
                                        <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306" />
                                    </svg>
                                    Vincular Cursos
                                </>}

                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </StepCreateCampaignTabStyles>
                </Row >
            </CreateCampaignStepBarStyles>
            <ContainerCreateCampaignStyles className="">

                <Row className="ml-3">
                    <ContainerFormStyle>
                        {
                            renderActiveTab(activeTab)
                        }
                    </ContainerFormStyle>
                </Row>
            </ContainerCreateCampaignStyles>
            <CreateCampaignFooterStyles className="">
                <Container fluid={true} className="py-4">
                    <Row className="justify-content-between mx-5">
                        {activeTab === true ?
                            <><div></div>
                                <PrimaryPurpleButton
                                    className="px-4 mt-0"
                                    onClick={() => handleNextStep()}
                                //disabled={!isValid}
                                >
                                    <Row className="p-0 m-0">
                                        <Col md="auto" className="my-auto">
                                            <p>Próximo passo: Vincular Cursos</p>
                                        </Col>
                                        <Col md="auto" className="pl-0">
                                            <i className="bi-arrow-right" />
                                        </Col>
                                    </Row>
                                </PrimaryPurpleButton></> :
                            <>
                                <TertiaryGreenButton
                                    className="px-4 mt-0"
                                    onClick={() => setActiveTab(true)}
                                //disabled={!isValid}
                                >
                                    <Row className="p-0 m-0">
                                        <Col md="auto" className="pl-0">
                                            <i className="bi-arrow-left" />
                                        </Col>
                                        <Col md="auto" className="my-auto">
                                            <p>Passo anterior: Dados da Campanha</p>
                                        </Col>

                                    </Row>
                                </TertiaryGreenButton>
                                <PrimaryGreenButton
                                    className="px-4 mt-0"
                                    onClick={() => saveCampaign()}
                                    disabled={listCourses.filter(item => item.selected === true).map(item => item.id).length < 1}
                                >
                                    <Row className="p-0 m-0">
                                        <Col md="auto" className="my-auto">
                                            <p>Criar Campanha</p>
                                        </Col>
                                        <Col md="auto" className="pl-0">
                                            <i className="bi-check2" />
                                        </Col>
                                    </Row>
                                </PrimaryGreenButton>
                            </>
                        }
                    </Row>
                </Container>
            </CreateCampaignFooterStyles >

            <ModalSuccessCreateCampaign show={showSuccessModal} handleCloseSuccessModal={handleCloseSuccessModal} />
            <ModalErrorCreateCampaign show={showErrorModal} handleCloseErrorModal={handleCloseErrorModal} />

        </>
    )

}
const CreateCampaignFooterStyles = styled.footer`
position: fixed;
    width: 100%;
    bottom: 0;
    padding: 0 2px 0 2px;
    background-color:#FFFFFF;
    border: none;
    box-shadow: 0px -0.14vmin 0.28vmin rgba(0, 0, 0, 0.1);
    z-index:100;
    
    button {
        min-width: 31.5vmin;
        i {
            font-size: 3.33vmin;
        }
        p {
            font-size: 2vmin;
        }
    }
`


const ContainerFormStyle = styled(Container)`
widt: 600px;
.line{
    width: 552px;
height: 1px;
background: #E8E8E8;
}
.button-radio {
    font-size: 16px;
    line-height: 24px;
    color: #777575;

    input {
      width: 32px;
      height: 32px;
      accent-color: #EA694C;
    }
    label {
      padding-left: 20px;
      padding-top: 8px;
    }
  }
  .button-radio input:checked{
    background-color: #EA694C;
}

.bi-search{
    position: absolute;
    top: 5px;
    right: 30px;
    font-size: 24px;

   }
h2{
    text-align: left;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #514F4E;
    margin-bottom: 0px;
}
h3{
    text-align: left;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #514F4E;
}

h4{
    text-align: left;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #514F4E;
}
.input-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 8px;
    width: 100%;
    height: 48px;
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    &:focus {
      box-shadow: none !important;
    }
  }


`
const ContainerCreateCampaignStyles = styled(Container)`
margin-top: 160px;
margin-bottom: 160px;
.sub-title{
    gap: 0px;
    opacity: 0px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #514F4E;    
}
.icon-calendar{
    position: absolute;
    left: 181px;
    top: 8px;
    font-size: 21px;
}
.form-control {
    background: #fff;

    padding: 12px 8px !important;
    width: 100% !important;
    height: 50px !important;

    border: 1px solid #a6a6a6;
    border-radius: 8px;
  }

`


const StepCreateCampaignTabStyles = styled(Col)`
    z-index:100;
    //max-width:80vw;
    .nav {
    place-content: center;
    }
    .nav-link{
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    background-color:#f9f9f9!important;
    color: #218749!important;
    }
    i {
        font-size: 24px;
    }
    .nav-link.active{
        border-bottom:4px solid #C57700;
    }

    @media screen and (max-width: 990px){
        max-width:95% !important;
        padding: 0px 12px !important;
        margin-left: 0 !important;
        z-index:0;
        /* border: solid 1px blue; */

        .nav-link {
            font-size:  24px;
        }

        .nav-item {
            float: left !important;
            width: 50% !important;
            margin: 0 !important;
        }
    }
`


const CreateCampaignStepBarStyles = styled(Container)`

    @media(min-height: 900px) {
       top: 7vh;        
    }
    @media(max-height: 900px) {
        top: 8vh;    
    }
    @media(max-height: 800px) {
        top: 9vh;
    }  
    @media(max-height: 700px) {
        top: 10vh;   
    }
    box-sizing: border-box;
    z-index:50;
    background:#F9F9F9;
    position:fixed;
    width:100%;
    border-radius:0px;

    i.bi-clock {
        color: #C57700;
        font-size:3.33vmin;
    }

    i.bi-check2-circle {        
        color: #108314;
        font-size:4vmin;     
    }
    i.bi-dash-circle { 
        color: #514F4E;  
        font-size:3.33vmin;     
    }

    p.step-number {
        border-radius: 50%;
        width: 4.16vmin;
        height: 4.16vmin;
        padding-top: 0.56vmin;
        border: 0.28vmin solid #514F4E;
        color: #514F4E;
        text-align: center;
        font-style: normal;
        font-weight: normal; 
        font-size:2.22vmin;
        
    }
    
    p{
        color: #514F4E;
        margin-bottom:0px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 2.5vmin;
        line-height: 20px;  
    }

    p.step-done     
    {
        color: #108314 !important;
    }
    p.step-current
    {
        color: #C57700 !important;
    }

    button {
        background: transparent !important;
        border: none;
        box-shadow:none!important;

        &:hover {
            background: transparent !important;
            color: #000;
            border: none !important;
            outline: none !important;
        }
        .btn-primary:not(:disabled):not(.disabled):active:focus{
            box-shadow:none!important;

        }
        &:active{
            box-shadow:none!important;

        }
        
        &:focus:not(:disabled) {
            outline: none !important;
            box-shadow:none!important;

        }
    }
    button.step-done     
    {
        p{
            color: #108314 !important;
        }
    }
    button.step-current
    {
        p{
            color: #C57700 !important;
        }
    }

    button.step-done.selected-step     
    {
        border-bottom: 3px solid#108314!important;
    }
    button.step-current.selected-step    
    {
        border-bottom: 3px solid#C57700!important;
    }
    button.selected-step    
    {
        border-bottom: 3px solid#514F4E!important;
    }
`
